<template>
  <div>
    <!-- 操作框 -->
    <div
      v-if="0"
      style="
        display: flex;
        justify-content: space-between;
        margin-bottom: -10px;
        height: 60px;
      "
    >
      <el-button
        style="margin-bottom: 20px"
        @click="
          () => {
            this.$router.back()
          }
        "
        >返回</el-button
      >
      <el-form :inline="true" :model="params" v-if="0">
        <el-form-item label="学校" class="formItemBoxStyle">
          <el-input v-model="params.examCode"></el-input>
        </el-form-item>
        <el-button v-throttle type="primary" @click="sendAsk">查询</el-button>
        <el-button v-throttle @click="onEmpty">重置</el-button>
      </el-form>
    </div>
    <!-- height: calc(100% - 60px); -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% - 20px);
        width: 100%;
      "
    >
      <!-- 监考设备选择 -->
      <div
        class="proctoringEquipmentBox"
        v-loading="loading"
        element-loading-text="设备初始化中"
      >
        <el-tooltip :content="dropDownBox.examName" placement="top-start">
          <div class="selectBox ellipsis">
            {{ dropDownBox.examName }}
            <i
              style="
                width: 30px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
              "
              class="el-icon-caret-bottom"
            ></i>
            <el-select
              v-model="examId"
              style="
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              @change="changDropDown"
            >
              <el-option
                v-for="item in dropDownList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              >
              </el-option>
            </el-select>
          </div>
        </el-tooltip>

        <el-card class="examInfoBox">
          <div slot="header" class="clearfix">
            <span>考试信息</span>
          </div>
          <div>
            考试科目：<span>{{
              dropDownBox.subjectTypeList | subjectTypeFilter
            }}</span>
          </div>
          <div>
            开始时间：<span>{{ dropDownBox.examStartTime | dayList }}</span>
          </div>
          <div>
            考试状态：<span style="color: #1890ff">{{
              dropDownBox.examStatus | examStatusFilter
            }}</span>
          </div>
        </el-card>
        <div class="contentTop">考场巡考监控设备选择</div>
        <el-collapse>
          <el-collapse-item
            v-for="(item, i) in patrolList"
            :key="i + item.sysOrgExamPlaceName"
            :title="item.sysOrgExamPlaceName"
          >
            <div
              class="ellipsis"
              v-for="(val, j) in item.examPlaceRoomPatrolList"
              :key="j"
              v-show="
                val.cameraLocationType == 1 || val.cameraLocationType == 2
              "
              @mousedown="mousedownFunc(val, item.sysOrgExamPlaceName)"
              @mouseup.capture="mouseupFunc(val, item.sysOrgExamPlaceName)"
              @mouseout="mouseoutFunc(val, item.sysOrgExamPlaceName)"
              @click="videoInfoList(val, item.sysOrgExamPlaceName)"
              :style="
                videoList.some((e) => e?.streamAddress === val.streamAddress)
                  ? 'color: #1890FF;padding-right:0;display:flex;align-items: center;justify-content: space-between;'
                  : ''
              "
            >
              {{
                val.sysOrgExamPlaceRoomName +
                '-' +
                val.cameraLocation +
                '-' +
                val.cameraStatusInfo
              }}
              <img
                v-show="
                  videoList.some((e) => e?.streamAddress === val.streamAddress)
                "
                style="width: 18px; height: 18px; margin-right: 4px"
                src="@/assets/icon_patrol_play.png"
              />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 画面布局 -->
      <div class="screenLayoutBox">
        <div class="contentTop">
          <div>
            画面布局
            <span @click="setVideoListNum(6, 1)">
              <img
                v-if="videoNum === 1"
                src="@/assets/icon_patrol_one_select.png"
              />
              <img v-else src="@/assets/icon_patrol_one_normal.png" />
            </span>
            <span @click="setVideoListNum(12, 2)">
              <img
                v-if="videoNum === 2"
                src="@/assets/icon_patrol_four_select.png"
              />
              <img v-else src="@/assets/icon_patrol_four_normal.png" />
            </span>
            <span @click="setVideoListNum(24, 3)">
              <img
                v-if="videoNum === 3"
                src="@/assets/icon_patrol_nine_select.png"
              />
              <img v-else src="@/assets/icon_patrol_nine_normal.png" />
            </span>
          </div>
          <div>
            <el-button type="danger" plain @click="closeBtn(false)"
              >关闭监控</el-button
            >
            <el-button
              style="margin-right: 10px"
              @click="
                () => {
                  this.$router.back()
                }
              "
              >返回</el-button
            >
          </div>
        </div>
        <div class="contentVideoBox">
          <!-- <draggable v-model="videoList"></draggable> -->
          <div v-for="(item, i) in videoList" :key="i" :class="itemClass">
            <div
              class="proportionBox"
              @mousedown="mousedownVideoFunc(i)"
              @mouseup.capture="mouseupVideoFunc(i)"
              @mouseout="mouseoutVideoFunc(i)"
              @click="getSubscript(i)"
              :style="
                selected_subscript == i
                  ? 'box-shadow: 0px 0px 15px rgb(0, 128, 255);'
                  : ''
              "
            >
              <flv-com ref="flvRef" :id="`flvID${i}`" />
              <div class="proportionBoxTit">
                <template v-if="item">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ i + 1 }}- {{ item ? item.name : '' }}&nbsp;&nbsp;{{
                      item
                        ? item?.sysOrgExamPlaceRoomName +
                          '-' +
                          item?.cameraLocation
                        : ''
                    }}
                    <span
                      @click="closeBtn(true, i)"
                      class="el-icon-close"
                      style="padding-right: 6px"
                    ></span>
                  </div>
                </template>
                <template v-else>
                  <span style="display: block; text-align: center"
                    ><span style="float: left">{{ i + 1 }}</span
                    >选中此区域后，在左侧选择监控进行播放</span
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import flvCom from '@/components/flvCom.vue'
// import JessibucaVideoCom from '@/components/JessibucaVideoCom.vue'
import { examInfoArrange } from '@/api/exam/exam.js'
import dayjs from 'dayjs'
import mpegtsCom from '@/components/mpegtsVideoCom.vue'
import { getPatrolList } from '@/api/exam/examVideoCheck.js'
import { debounce } from '@/utils/util'
export default {
  // 考试巡查
  name: 'examVideoCheck',
  // components: { flvCom },
  components: { flvCom: mpegtsCom },
  data() {
    return {
      examId: null,
      // 条件
      params: {},
      patrolList: [],
      // 监考设备选择

      // 画面布局
      videoList: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      // 选中的画面布局
      selected_subscript: null,
      loading: false,
      videoNum: 2,
      // 拖拽选中框
      videoLiData: null,
      // 选中
      mousedownVideoKey: null,
      // 释放
      mouseupVideoKey: null,
      keyList: [],
      body: null,
      cursor: false,
      // 下拉框
      dropDownList: [],
      dropDownBox: {},
    }
  },
  async created() {},
  async mounted() {
    this.body = document.querySelector('body')
    this.body.addEventListener(
      'mousedown',
      () => {
        if (!this.cursor) return
        this.$nextTick(() => {
          this.body.style.cursor = 'alias'
        })
      },
      false
    )
    this.body.addEventListener(
      'mouseup',
      () => {
        this.cursor = false
        this.body.style.cursor = ''
      },
      false
    )
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')

    await this.getDropDownFunc()
    if (this.$route.query.examId) {
      this.examId = Number(this.$route.query.examId)
    } else {
      this.examId = this.dropDownList[0].examId
    }
    this.changDropDown(this.examId)
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 获取考试下拉列表
    async getDropDownFunc() {
      await examInfoArrange(1, -1, {}).then((res) => {
        if (res.success) {
          this.dropDownList = res.data.flatMap((e) =>
            e.children ? [e, ...e.children] : [e]
          )
        }
      })
    },
    // 选中考试
    changDropDown(key) {
      if (Number(this.$route.query.examId) != Number(key)) {
        // this.$router.replace({ path: '/exam/examVideoCheck?examId=' + key })
        const query = { examId: key }
        this.$router.replace({ query })
      }
      this.closeBtn(false)
      this.dropDownBox = this.dropDownList.find((e) => e.examId === key)
      this.loading = true
      this.getPatrolListFunc(this.examId)
    },
    // 获取all
    getPatrolListFunc(val) {
      getPatrolList(val)
        .then((res) => {
          if (res.success) {
            this.patrolList = res.data
          } else {
            this.$message.info(res.msg)
          }
          this.loading = false
        })
        .catch((err) => {})
    },
    mousedownFunc(val, name) {
      this.videoLiData = { ...val, name }
      this.cursor = true
    },
    mouseupFunc(val, name) {
      // console.log('2', val, name)
      this.videoLiData = null
    },
    mouseoutFunc(val, name) {
      // console.log('3',val,name)
    },
    // 选中
    mousedownVideoFunc(key) {
      this.videoLiData = null
      this.mousedownVideoKey = key
      this.keyList = [this.videoList[key]]
      this.cursor = true
    },
    // 释放
    mouseupVideoFunc(key) {
      if (this.mousedownVideoKey == key) return
      this.mouseupVideoKey = key
      if (this.videoLiData) {
        this.videoInfoList(
          this.videoLiData,
          this.videoLiData.name,
          this.mouseupVideoKey
        )
      } else {
        this.keyList.push(this.videoList[key])
        const keyNum = [this.mouseupVideoKey, this.mousedownVideoKey]
        this.keyList.forEach((e, i) => {
          if (e) {
            this.videoInfoList1(e, e.name, keyNum[i])
          } else {
            this.closeBtn(true, keyNum[i])
          }
        })
        this.keyList = []
      }
    },
    // 移动
    mouseoutVideoFunc(key) {
      //   console.log('3', key)
    },
    // 点击监控设备选择 防抖
    videoInfoList: debounce(function (val, name, key = null) {
      if (!val.streamAddress) {
        return this.$message.info(val.cameraStatusInfo)
      }
      let index
      if (typeof this.selected_subscript === 'number') {
        index = this.selected_subscript
      } else {
        index = this.videoList.findIndex((e) => e == null)
        index = index === -1 ? '0' : index
      }
      if (typeof key === 'number') {
        index = key
      }
      if (this.videoList[index]) {
        // 切换视频流
        this.$refs.flvRef[index].switchURLPlayer(
          this.$mediaHttpAddress(val.streamAddress)
        )
      } else {
        // 初始化视频进行播放
        this.$refs.flvRef[index].url = this.$mediaHttpAddress(val.streamAddress)
        this.$refs.flvRef[index].initPlayer()
      }
      this.videoList[index] = { ...val, name }
      this.selected_subscript = null
      // 强刷
      this.$forceUpdate()
    }, 300),
    videoInfoList1(val, name, key = null) {
      if (!val.streamAddress) {
        return this.$message.info(val.cameraStatusInfo)
      }
      let index
      if (typeof this.selected_subscript === 'number') {
        index = this.selected_subscript
      } else {
        index = this.videoList.findIndex((e) => e == null)
        index = index === -1 ? '0' : index
      }
      if (typeof key === 'number') {
        index = key
      }
      if (this.videoList[index]) {
        // 切换视频流
        this.$refs.flvRef[index].switchURLPlayer(
          this.$mediaHttpAddress(val.streamAddress)
        )
      } else {
        // 初始化视频进行播放
        this.$refs.flvRef[index].url = this.$mediaHttpAddress(val.streamAddress)
        this.$refs.flvRef[index].initPlayer()
      }
      this.videoList[index] = { ...val, name }
      this.selected_subscript = null
      // 强刷
      this.$forceUpdate()
    },
    // 画面布局切换
    setVideoListNum(num, key) {
      this.videoNum = key
      if (this.videoList.length < num) {
        this.videoList = [
          ...this.videoList,
          ...Array(num - this.videoList.length).fill(null),
        ]
      } else if (this.videoList.length > num) {
        for (let index = this.videoList.length - 1; index >= num; index--) {
          if (this.videoList[index]) {
            this.$refs.flvRef[index].destroyPlayer()
            this.videoList[index] = null
          }
        }
        this.videoList = this.videoList.slice(0, num)
      }
      // 清除超出的下标
      if (this.selected_subscript > num) {
        this.selected_subscript = null
      }
    },
    // 点击画面布局位置
    getSubscript(i) {
      this.selected_subscript = this.selected_subscript === i ? null : i
    },
    // sendAsk() {},
    // onEmpty() {},
    // 关闭监控
    closeBtn(flag, index) {
      if (flag) {
        if (this.videoList[index]) {
          this.$refs.flvRef[index].destroyPlayer()
          this.videoList[index] = null
        }
      } else {
        this.videoList.forEach((e, i) => {
          if (e) {
            this.$refs.flvRef[i].destroyPlayer()
            this.videoList[i] = null
          }
        })
      }
      this.$forceUpdate()
    },
  },
  computed: {
    itemClass() {
      // switch (this.videoList.length) {
      switch (this.videoNum) {
        case 1:
          return 'singleItem'
        case 2:
          return 'fourItems'
        default:
          return 'multipleItems'
      }
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
    examStatusFilter(key) {
      const statusMap = {
        1: '草稿',
        2: '等待考试',
        3: '正在考试',
        4: '考试完成',
        5: '等待补考',
        6: '正在补考',
        7: '考试终止',
      }
      return statusMap[key] || `错误的值${key}`
    },
    subjectTypeFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const subjectMap = {
        1: '物理',
        2: '生物',
        3: '化学',
      }
      return val.map((item) => subjectMap[item] || `错误的值${item}`).join(',')
    },
  },
}
</script>

<style lang="scss" scoped>
.contentTop {
  height: 60px;
  // height: 80px;
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 60px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  span {
    margin-left: 20px;
    cursor: pointer;
    color: #549eff;
    width: 40px;
    height: 40px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      margin: 0;
      font-size: 0;
    }
  }
}

.proctoringEquipmentBox {
  background: #fff;
  height: calc(100% - 20px);
  // height: calc(100% - 40px);
  width: 300px;
  margin-right: 20px;
  overflow-y: auto;
  ::v-deep .el-collapse-item__header {
    background: #f2f2f2;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    height: 40px;
  }
  ::v-deep .el-collapse-item__content {
    box-sizing: border-box;
    padding-bottom: 10px;
    > div {
      font-size: 16px;
      height: 32px;
      line-height: 32px;
      // cursor: pointer;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 10px;
      &:hover {
        background: #d1e9ff;
      }
    }
    .active {
      color: #549eff;
    }
  }
}

.screenLayoutBox {
  background: #fff;
  height: calc(100% - 20px);
  // height: calc(100% - 40px);
  width: calc(100% - 230px);
}
.contentVideoBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: auto;
}
// 16:9比例
.proportionBox {
  position: relative;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  padding-bottom: 56.25%;
  // cursor: pointer;
  background: url(../../../assets/icon_patrol_lapse.png) center no-repeat;
}
.proportionBoxTit {
  position: absolute;
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  line-height: 24px;
  height: 26px;
  font-size: 16px;
  color: #fff;
  box-sizing: border-box;
  padding-left: 20px;
}
.singleItem {
  width: 100%;
  box-sizing: border-box;
  background: #333333;
  margin-bottom: 10px;
}

.fourItems {
  width: calc(50% - 5px);
  box-sizing: border-box;
  margin-bottom: 10px;
  background: #333333;
}

.multipleItems {
  width: calc(33.33% - 5px);
  box-sizing: border-box;
  margin-bottom: 10px;
  background: #333333;
}

* {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

.selectBox {
  height: 42px;
  position: relative;
  width: 100%;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 30px;
  cursor: pointer;
  border-bottom: 2px solid #f2f2f2;
}
// 考试信息
.examInfoBox {
  width: 100%;
  font-size: 15px;
  color: #978f9d;
  span {
    color: #000;
  }
  ::v-deep .el-card__header {
    margin: 0;
    padding: 4px 10px;
  }
  ::v-deep .el-card__body {
    padding: 10px;
  }
}
</style>
